import { colors } from '@mui/material'

const dimensions = {
  width: 734,
  height: 1024,
  packWidth: 734,
  packHeight: 1200,
}

const getDimensions = (url, callback) => {
  if (!url) {
    // eslint-disable-next-line
    callback({ width: 0, height: 0 })
    return
  }
  const img = new Image()
  img.addEventListener('load', function () {
    // eslint-disable-next-line
    callback({ width: this.naturalWidth, height: this.naturalHeight })
  })
  img.src = url
}

const typeOptions = [
  {
    title: 'Single',
    value: 'single',
  },
  {
    title: 'Multi',
    value: 'multi',
  },
]

const multiOptions = [
  {
    title: 'Small (15 total quantity)',
    value: 'small',
    quantities: [5, 4, 3, 2, 1],
  },
  {
    title: 'Medium (25 total quantity)',
    value: 'medium',
    quantities: [10, 8, 4, 2, 1],
  },
  {
    title: 'Large (50 total quantity)',
    value: 'large',
    quantities: [20, 16, 8, 5, 1],
  },
  {
    title: 'Super (100 total quantity)',
    value: 'super',
    quantities: [40, 30, 23, 6, 1],
  },
  {
    title: 'Ultra (200 total quantity)',
    value: 'ultra',
    quantities: [80, 60, 46, 12, 2],
  },
  {
    title: 'Custom',
    value: 'custom',
    quantities: [1, 1, 1, 1, 1],
  },
]

const rarityOptions = [
  {
    title: 'Legendary',
    value: 5,
  },
  {
    title: 'Epic',
    value: 4,
  },
  {
    title: 'Rare',
    value: 3,
  },
  {
    title: 'Uncommon',
    value: 2,
  },
  {
    title: 'Common',
    value: 1,
  },
]

const rarityStrings = ['Common', 'Uncommon', 'Rare', 'Epic', 'Legendary']
const rarityColors = [
  '#fff',
  colors.green[500],
  colors.blue[500],
  colors.purple[500],
  colors.orange[500],
]

const animationOptions = [
  {
    title: 'None',
    value: '',
  },
  {
    title: 'cubes-middle-3d-tech-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/cubes-middle-3d-tech-01.webp',
  },
  {
    title: 'dots-big-random-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/dots-big-random-01.webp',
  },
  {
    title: 'lines-grid-tech-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/lines-grid-tech-01.webp',
  },
  {
    title: 'particles-corners-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-corners-01.webp',
  },
  {
    title: 'particles-radial-zoom-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-radial-zoom-01.webp',
  },
  {
    title: 'particles-random-top-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-random-top-01.webp',
  },
  {
    title: 'particles-rays-bottom-up-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-rays-bottom-up-01.webp',
  },
  {
    title: 'particles-top-down-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-top-down-01.webp',
  },
  {
    title: 'particles-up-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/particles-up-01.webp',
  },
  {
    title: 'streamers-dots-lines-vertical-zoom-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/streamers-dots-lines-vertical-zoom-01.webp',
  },
  {
    title: 'streamers-particles-lines-diagonal-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/streamers-particles-lines-diagonal-01.webp',
  },

  {
    title: 'clouds-particles-rays-bright-diagonal-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/clouds-particles-rays-bright-diagonal-01.webp',
  },
  {
    title: 'fire-flames-up-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/fire-flames-up-01.webp',
  },
  {
    title: 'hexagons-zoom-tech-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/hexagons-zoom-tech-01.webp',
  },
  {
    title: 'plasma-fabric-left-right-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/plasma-fabric-left-right-01.webp',
  },
  {
    title: 'rays-dots-zoom-bright-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/rays-dots-zoom-bright-01.webp',
  },
  {
    title: 'stars-dots-streamers-zoom-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/stars-dots-streamers-zoom-01.webp',
  },
  {
    title: 'streamers-sparkles-lines-dots-down-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/streamers-sparkles-lines-dots-down-01.webp',
  },
  {
    title: 'waves-particles-bottom-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/waves-particles-bottom-01.webp',
  },
  {
    title: 'zoom-lines-particles-01',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/animations/zoom-lines-particles-01.webp',
  },
]

const textureOptions = [
  {
    title: 'None',
    value: '',
  },
  {
    title: 'basketball1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/basketball1.jpg',
  },
  {
    title: 'bokkah1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/bokkah1.jpg',
  },
  {
    title: 'brick1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick1.jpg',
  },
  {
    title: 'brick2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick2.jpg',
  },
  {
    title: 'brick3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick3.jpg',
  },
  {
    title: 'brick4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick4.jpg',
  },
  {
    title: 'brick5',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick5.jpg',
  },
  {
    title: 'brick6',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick6.jpg',
  },
  {
    title: 'brick7',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick7.jpg',
  },
  {
    title: 'brick8',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick8.jpg',
  },
  {
    title: 'brick9',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/brick9.jpg',
  },
  {
    title: 'burlap1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/burlap1.jpg',
  },
  {
    title: 'burlap2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/burlap2.jpg',
  },
  {
    title: 'cracks1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/cracks1.jpg',
  },
  {
    title: 'cracks2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/cracks2.jpg',
  },
  {
    title: 'curves1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/curves1.jpg',
  },
  {
    title: 'curves2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/curves2.jpg',
  },
  {
    title: 'curves2b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/curves2b.jpg',
  },
  {
    title: 'dots1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/dots1.jpg',
  },
  {
    title: 'droplets1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/droplets1.jpg',
  },
  {
    title: 'droplets2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/droplets2.jpg',
  },
  {
    title: 'droplets3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/droplets3.jpg',
  },
  {
    title: 'fabric1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/fabric1.jpg',
  },
  {
    title: 'fibers1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/fibers1.jpg',
  },
  {
    title: 'flowers1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/flowers1.jpg',
  },
  {
    title: 'flowers2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/flowers2.jpg',
  },
  {
    title: 'flowers3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/flowers3.jpg',
  },
  {
    title: 'grass1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grass1.jpg',
  },
  {
    title: 'grate1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grate1.jpg',
  },
  {
    title: 'grate1b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grate1b.jpg',
  },
  {
    title: 'grate2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grate2.jpg',
  },
  {
    title: 'grid1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grid1.jpg',
  },
  {
    title: 'grid2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grid2.jpg',
  },
  {
    title: 'grid2b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grid2b.jpg',
  },
  {
    title: 'grid3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grid3.jpg',
  },
  {
    title: 'grid4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grid4.jpg',
  },
  {
    title: 'grid4b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/grid4b.jpg',
  },
  {
    title: 'leaves1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/leaves1.jpg',
  },
  {
    title: 'lines1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines1.jpg',
  },
  {
    title: 'lines2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines2.jpg',
  },
  {
    title: 'lines2b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines2b.jpg',
  },
  {
    title: 'lines3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines3.jpg',
  },
  {
    title: 'lines4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines4.jpg',
  },
  {
    title: 'lines4b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines4b.jpg',
  },
  {
    title: 'lines5',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines5.jpg',
  },
  {
    title: 'lines5b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines5b.jpg',
  },
  {
    title: 'lines6',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/lines6.jpg',
  },
  {
    title: 'mandala1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/mandala1.jpg',
  },
  {
    title: 'marble1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/marble1.jpg',
  },
  {
    title: 'marble2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/marble2.jpg',
  },
  {
    title: 'marble3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/marble3.jpg',
  },
  {
    title: 'noise1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise1.jpg',
  },
  {
    title: 'noise2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise2.jpg',
  },
  {
    title: 'noise3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise3.jpg',
  },
  {
    title: 'noise4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise4.jpg',
  },
  {
    title: 'noise5',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise5.jpg',
  },
  {
    title: 'noise5b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise5b.jpg',
  },
  {
    title: 'noise6',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise6.jpg',
  },
  {
    title: 'noise7',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise7.jpg',
  },
  {
    title: 'noise8',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise8.jpg',
  },
  {
    title: 'noise9',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/noise9.jpg',
  },
  {
    title: 'pattern1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern1.jpg',
  },
  {
    title: 'pattern2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern2.jpg',
  },
  {
    title: 'pattern3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern3.jpg',
  },
  {
    title: 'pattern4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern4.jpg',
  },
  {
    title: 'pattern4b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern4b.jpg',
  },
  {
    title: 'pattern5',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern5.jpg',
  },
  {
    title: 'pattern5b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern5b.jpg',
  },
  {
    title: 'pattern6',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/pattern6.jpg',
  },
  {
    title: 'plant1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/plant1.jpg',
  },
  {
    title: 'plaster1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/plaster1.jpg',
  },
  {
    title: 'plaster2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/plaster2.jpg',
  },
  {
    title: 'plaster3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/plaster3.jpg',
  },
  {
    title: 'plaster4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/plaster4.jpg',
  },
  {
    title: 'radial1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/radial1.jpg',
  },
  {
    title: 'radial2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/radial2.jpg',
  },
  {
    title: 'radial3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/radial3.jpg',
  },
  {
    title: 'rock1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/rock1.jpg',
  },
  {
    title: 'sand1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand1.jpg',
  },
  {
    title: 'sand2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand2.jpg',
  },
  {
    title: 'sand3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand3.jpg',
  },
  {
    title: 'sand4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand4.jpg',
  },
  {
    title: 'sand5',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand5.jpg',
  },
  {
    title: 'sand6',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand6.jpg',
  },
  {
    title: 'sand7',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/sand7.jpg',
  },
  {
    title: 'skin1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/skin1.jpg',
  },
  {
    title: 'skin1b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/skin1b.jpg',
  },
  {
    title: 'smooth1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/smooth1.jpg',
  },
  {
    title: 'stone1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/stone1.jpg',
  },
  {
    title: 'tile1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile1.jpg',
  },
  {
    title: 'tile1b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile1b.jpg',
  },
  {
    title: 'tile2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile2.jpg',
  },
  {
    title: 'tile2b',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile2b.jpg',
  },
  {
    title: 'tile3',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile3.jpg',
  },
  {
    title: 'tile4',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile4.jpg',
  },
  {
    title: 'tile5',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/tile5.jpg',
  },
  {
    title: 'trees1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/trees1.jpg',
  },
  {
    title: 'wood1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/wood1.jpg',
  },
  {
    title: 'wood2',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/wood2.jpg',
  },
  {
    title: 'yarn1',
    value:
      'https://creatorbay.appspot.com.storage.googleapis.com/assets/textures/yarn1.jpg',
  },
]

const getTotalNFTs = (cards) => {
  return !cards
    ? 0
    : cards.reduce((total, card) => {
        if (card.type === 'multi')
          return (
            total +
            card.multi.quantities.reduce(
              (total2, quantity) => total2 + parseInt(quantity),
              0
            )
          )
        else return total + parseInt(card.quantity)
      }, 0)
}

const cardSortOptions = [
  {
    title: 'Newest',
    value: 'dateCreated|desc',
  },
  {
    title: 'Oldest',
    value: 'dateCreated|asc',
  },
  {
    title: 'Quantity (High-Low)',
    value: 'quantity|desc',
  },
  {
    title: 'Quantity (Low-High)',
    value: 'quantity|asc',
  },
  {
    title: 'Title (A-Z)',
    value: 'title|asc',
  },
  {
    title: 'Title (Z-A)',
    value: 'title|desc',
  },
  {
    title: 'Rarity (Common-Legendary)',
    value: 'rarity|asc',
  },
  {
    title: 'Rarity (Legendary-Common)',
    value: 'rarity|desc',
  },
]

const nftSortOptions = [
  {
    title: 'Title (A-Z)',
    value: 'title|asc',
  },
  {
    title: 'Title (Z-A)',
    value: 'title|desc',
  },
  {
    title: 'Rarity (Common-Legendary)',
    value: 'rarity|asc',
  },
  {
    title: 'Rarity (Legendary-Common)',
    value: 'rarity|desc',
  },
]

const listingSortOptions = [
  {
    title: 'Price (Low-High)',
    value: 'price|asc',
  },
  {
    title: 'Price (High-Low)',
    value: 'price|desc',
  },
  {
    title: 'Serial # (Low-High)',
    value: 'serialNumber|asc',
  },
  {
    title: 'Serial # (High-Low)',
    value: 'serialNumber|desc',
  },
]

const cardUtils = {
  dimensions,
  getDimensions,
  typeOptions,
  multiOptions,
  rarityOptions,
  rarityStrings,
  rarityColors,
  animationOptions,
  textureOptions,
  getTotalNFTs,
  cardSortOptions,
  nftSortOptions,
  listingSortOptions,
}

export default cardUtils
